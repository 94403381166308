<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-tabs>
      <!-- english transltion -->
      <b-tab active title="English">
        <b-card title="News Marquee">
          <b-form
            class="repeater-form"
            @submit.prevent="repeatNewsMarquee(null)"
          >
            <b-row
              v-for="(marquee, marqueeIndex) in page.newsMarquee"
              :key="marqueeIndex"
              ref="sliderRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="8">
                    <b-form-group label="Title">
                      <b-form-input
                        v-model="page.newsMarquee[marqueeIndex].text"
                        placeholder="Text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="URL">
                      <b-form-input
                        v-model="page.newsMarquee[marqueeIndex].url"
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeNewsMarquee(null, marqueeIndex)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatNewsMarquee(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.newsMarquee.length === 0
                      ? "Add News Marquee"
                      : "Add New News"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Banner Slider">
          <b-form class="repeater-form" @submit.prevent="repeatSlider(null)">
            <b-row
              v-for="(slider, index) in page.mainSlider"
              :key="index"
              ref="sliderRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Banner Image">
                      <b-row>
                        <b-col cols="4">
                          <ComponentMediaSelector
                            :params="{ index, lang: null }"
                            buttonText="Select Icon"
                            @mediaSelected="handlebannerImgSelect"
                            :isMultiple="false"
                          />
                        </b-col>

                        <b-col cols="8" v-if="page.mainSlider[index].image">
                          <b-img
                            v-bind="mainProps"
                            class="mb-1"
                            :src="page.mainSlider[index].image"
                          ></b-img>
                          <a
                            @click="page.mainSlider[index].image = ''"
                            class="text-danger d-block"
                            href="javascript:void(0);"
                            >Remove Thumbnail</a
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Title">
                      <b-form-input
                        v-model="page.mainSlider[index].title"
                        placeholder="Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="URL">
                      <b-form-input
                        v-model="page.mainSlider[index].url"
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Video URL">
                      <b-form-input
                        v-model="page.mainSlider[index].videoUrl"
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Description">
                      <ckeditor
                        v-model="page.mainSlider[index].description"
                        placeholder="Description"
                      ></ckeditor>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeSlider(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSlider(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.mainSlider.length === 0
                      ? "Add Banner Slider"
                      : "Add New Slider"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Experiences">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.experienceSection.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.experienceSection.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="experience list"
              class="repeater-form"
              @submit.prevent="repeatExperience(null)"
            >
              <b-row
                v-for="(slider, index) in page.experienceSection.experiences"
                :key="index"
                ref="sliderRow"
              >
                <b-card :title="'Experience ' + (index + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.experienceSection.experiences[index].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group label="Locatiion">
                            <b-form-input
                              v-model="
                                page.experienceSection.experiences[index]
                                  .location
                              "
                              placeholder="Locatiion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="URL">
                            <b-form-input
                              v-model="
                                page.experienceSection.experiences[index].url
                              "
                              placeholder="URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <b-form-checkbox
                              checked="true"
                              v-model="
                                page.experienceSection.experiences[index]
                                  .comingSoon
                              "
                              name="check-button"
                              class="mb-1 mt-1"
                              switch
                              inline
                            >
                              Coming Soon
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index, lang: null }"
                                buttonText="Select Icon"
                                @mediaSelected="handleExperieeImgSelect"
                                :isMultiple="false"
                              />
                            </b-col>

                            <b-col
                              cols="8"
                              v-if="
                                page.experienceSection.experiences[index].image
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.experienceSection.experiences[index]
                                    .image
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.experienceSection.experiences[
                                    index
                                  ].image = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeExperience(null, index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatExperience(null)"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.experienceSection.experiences.length === 0
                        ? "Add Experience"
                        : "Add New Experience"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Stays">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.staySection.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.staySection.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="experience list"
              class="repeater-form"
              @submit.prevent="repeatStays(null)"
            >
              <b-row
                v-for="(slider, index) in page.staySection.stays"
                :key="index"
                ref="sliderRow"
              >
                <b-card :title="'Stay ' + (index + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="page.staySection.stays[index].title"
                              placeholder="Title"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group label="Locatiion">
                            <b-form-input
                              v-model="page.staySection.stays[index].location"
                              placeholder="Locatiion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="URL">
                            <b-form-input
                              v-model="page.staySection.stays[index].url"
                              placeholder="URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <b-form-checkbox
                              checked="true"
                              v-model="page.staySection.stays[index].comingSoon"
                              name="check-button"
                              class="mb-1 mt-1"
                              switch
                              inline
                            >
                              Coming Soon
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index, lang: null }"
                                buttonText="Select Icon"
                                @mediaSelected="handleStayImgSelect"
                                :isMultiple="false"
                              />
                            </b-col>

                            <b-col
                              cols="8"
                              v-if="page.staySection.stays[index].image"
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="page.staySection.stays[index].image"
                              ></b-img>
                              <a
                                @click="
                                  page.staySection.stays[index].image = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeStay(null, index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatStays(null)"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.staySection.stays.length === 0
                        ? "Add Stays"
                        : "Add New Stay"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Majestic Maharashtra">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.majesticMaharashtra.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Experience">
                  <v-select
                    multiple
                    label="title"
                    v-model="page.majesticMaharashtra.experiences"
                    :reduce="
                      (experience) => {
                        return { experience: experience._id };
                      }
                    "
                    placeholder="Select Experience Items"
                    :options="experiences"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.majesticMaharashtra.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Upcoming Events">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.eventSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.eventSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="News">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.newsSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.newsSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Social Media">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.socialMedia.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.socialMedia.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="Media list"
              class="repeater-form"
              @submit.prevent="repeatSocialMedia(null)"
            >
              <b-row
                v-for="(media, index1) in page.socialMedia.socialMediaList"
                :key="index1"
                ref="sliderRow"
              >
                <b-card :title="'Social Media ' + (index1 + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Media Type">
                            <v-select
                              label="Media Type"
                              v-model="
                                page.socialMedia.socialMediaList[index1]
                                  .mediaType
                              "
                              placeholder="Select Media Type"
                              :options="['Instagram', 'Twitter']"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="Redirect URL">
                            <b-form-input
                              v-model="
                                page.socialMedia.socialMediaList[index1].url
                              "
                              placeholder="Redirect URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group label="Description">
                            <ckeditor
                              v-model="
                                page.socialMedia.socialMediaList[index1]
                                  .description
                              "
                              placeholder="Description"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols = "12">
                          <b-card title="News Date">
                            <b-row>
                             <b-col class="text-center">
                             <b-form-group>
                               <b-calendar v-model=" page.socialMedia.socialMediaList[index1]
                                  .creationDate" block locale="en-US" />
                            </b-form-group>
                             </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index1, lang: null }"
                                buttonText="Select Image"
                                @mediaSelected="handleMediaSelect"
                                :isMultiple="false"
                              />
                            </b-col>
                            <b-col
                              cols="8"
                              v-if="
                                page.socialMedia.socialMediaList[index1]
                                  .imageUrl
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.socialMedia.socialMediaList[index1]
                                    .imageUrl
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.socialMedia.socialMediaList[
                                    index1
                                  ].imageUrl = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeSocialMedia(null, index1)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatSocialMedia(null)"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.socialMedia.socialMediaList.length === 0
                        ? "Add Social Media"
                        : "Add New Social media"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>
      </b-tab>
      <!-- hindi translation -->
      <b-tab title="Hindi">
        <b-card title="Banner Slider">
          <b-form class="repeater-form" @submit.prevent="repeatSlider('hi')">
            <b-row
              v-for="(slider, index) in page.translations.hi.mainSlider"
              :key="index"
              ref="sliderRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Banner Image">
                      <b-row>
                        <b-col cols="4">
                          <ComponentMediaSelector
                            :params="{ index, lang: 'hi' }"
                            buttonText="Select Icon"
                            @mediaSelected="handlebannerImgSelect"
                            :isMultiple="false"
                          />
                        </b-col>

                        <b-col
                          cols="8"
                          v-if="page.translations.hi.mainSlider[index].image"
                        >
                          <b-img
                            v-bind="mainProps"
                            class="mb-1"
                            :src="page.translations.hi.mainSlider[index].image"
                          ></b-img>
                          <a
                            @click="
                              page.translations.hi.mainSlider[index].image = ''
                            "
                            class="text-danger d-block"
                            href="javascript:void(0);"
                            >Remove Thumbnail</a
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Title">
                      <b-form-input
                        v-model="page.translations.hi.mainSlider[index].title"
                        placeholder="Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="URL">
                      <b-form-input
                        v-model="page.translations.hi.mainSlider[index].url"
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Video URL">
                      <b-form-input
                        v-model="
                          page.translations.hi.mainSlider[index].videoUrl
                        "
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Description">
                      <ckeditor
                        v-model="
                          page.translations.hi.mainSlider[index].description
                        "
                        placeholder="Description"
                      ></ckeditor>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeSlider('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSlider('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.mainSlider.length === 0
                      ? "Add Banner Slider"
                      : "Add New Slider"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Experiences">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.translations.hi.experienceSection.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.hi.experienceSection.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="experience list"
              class="repeater-form"
              @submit.prevent="repeatExperience('hi')"
            >
              <b-row
                v-for="(slider, index) in page.translations.hi.experienceSection
                  .experiences"
                :key="index"
                ref="sliderRow"
              >
                <b-card :title="'Experience ' + (index + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.translations.hi.experienceSection
                                  .experiences[index].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group label="Locatiion">
                            <b-form-input
                              v-model="
                                page.translations.hi.experienceSection
                                  .experiences[index].location
                              "
                              placeholder="Locatiion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="URL">
                            <b-form-input
                              v-model="
                                page.translations.hi.experienceSection
                                  .experiences[index].url
                              "
                              placeholder="URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <b-form-checkbox
                              checked="true"
                              v-model="
                                page.translations.hi.experienceSection
                                  .experiences[index].comingSoon
                              "
                              name="check-button"
                              class="mb-1 mt-1"
                              switch
                              inline
                            >
                              Coming Soon
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index, lang: 'hi' }"
                                buttonText="Select Icon"
                                @mediaSelected="handleExperieeImgSelect"
                                :isMultiple="false"
                              />
                            </b-col>

                            <b-col
                              cols="8"
                              v-if="
                                page.translations.hi.experienceSection
                                  .experiences[index].image
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.translations.hi.experienceSection
                                    .experiences[index].image
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.translations.hi.experienceSection.experiences[
                                    index
                                  ].image = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeExperience('hi', index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatExperience('hi')"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.translations.hi.experienceSection.experiences
                        .length === 0
                        ? "Add Experience"
                        : "Add New Experience"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Majestic Maharashtra">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.hi.majesticMaharashtra.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Experience">
                  <v-select
                    multiple
                    label="title"
                    v-model="
                      page.translations.hi.majesticMaharashtra.experiences
                    "
                    :reduce="
                      (experience) => {
                        return { experience: experience._id };
                      }
                    "
                    placeholder="Select Experience Items"
                    :options="experiences"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="
                      page.translations.hi.majesticMaharashtra.description
                    "
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Upcoming Events">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.hi.eventSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.hi.eventSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="News">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.hi.newsSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.hi.newsSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Social Media">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.translations.hi.socialMedia.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.hi.socialMedia.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="Media list"
              class="repeater-form"
              @submit.prevent="repeatSocialMedia('hi')"
            >
              <b-row
                v-for="(media, index1) in page.translations.hi.socialMedia
                  .socialMediaList"
                :key="index1"
                ref="sliderRow"
              >
                <b-card :title="'Social Media ' + (index1 + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Media Type">
                            <v-select
                              label="Media Type"
                              v-model="
                                page.translations.hi.socialMedia
                                  .socialMediaList[index1].mediaType
                              "
                              placeholder="Select Media Type"
                              :options="['Instagram', 'Twitter']"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="Redirect URL">
                            <b-form-input
                              v-model="
                                page.translations.hi.socialMedia
                                  .socialMediaList[index1].url
                              "
                              placeholder="Redirect URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group label="Description">
                            <ckeditor
                              v-model="
                                page.translations.hi.socialMedia
                                  .socialMediaList[index1].description
                              "
                              placeholder="Description"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols = "12">
                          <b-card title="News Date">
                            <b-row>
                             <b-col class="text-center">
                             <b-form-group>
                               <b-calendar v-model="page.translations.hi.socialMedia.socialMediaList[index1]
                                  .creationDate" block locale="en-US" />
                            </b-form-group>
                             </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index1, lang: 'hi' }"
                                buttonText="Select Image"
                                @mediaSelected="handleMediaSelect"
                                :isMultiple="false"
                              />
                            </b-col>
                            <b-col
                              cols="8"
                              v-if="
                                page.translations.hi.socialMedia
                                  .socialMediaList[index1].imageUrl
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.translations.hi.socialMedia
                                    .socialMediaList[index1].imageUrl
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.translations.hi.socialMedia.socialMediaList[
                                    index1
                                  ].imageUrl = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeSocialMedia('hi', index1)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatSocialMedia('hi')"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.translations.hi.socialMedia.socialMediaList
                        .length === 0
                        ? "Add Social Media"
                        : "Add New Social media"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>
      </b-tab>

      <!-- marathi translation -->
      <b-tab title="Marathi">
        <b-card title="News Marquee">
          <b-form
            class="repeater-form"
            @submit.prevent="repeatNewsMarquee('mr')"
          >
            <b-row
              v-for="(marquee, marqueeIndex) in page.translations.mr
                .newsMarquee"
              :key="marqueeIndex"
              ref="sliderRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="8">
                    <b-form-group label="Title">
                      <b-form-input
                        v-model="
                          page.translations.mr.newsMarquee[marqueeIndex].text
                        "
                        placeholder="Text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="URL">
                      <b-form-input
                        v-model="
                          page.translations.mr.newsMarquee[marqueeIndex].url
                        "
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeNewsMarquee('mr', marqueeIndex)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatNewsMarquee('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.newsMarquee.length === 0
                      ? "Add News Marquee"
                      : "Add New News"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Banner Slider">
          <b-form class="repeater-form" @submit.prevent="repeatSlider('mr')">
            <b-row
              v-for="(slider, index) in page.translations.mr.mainSlider"
              :key="index"
              ref="sliderRow"
            >
              <b-col cols="10">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Banner Image">
                      <b-row>
                        <b-col cols="4">
                          <ComponentMediaSelector
                            :params="{ index, lang: 'mr' }"
                            buttonText="Select Icon"
                            @mediaSelected="handlebannerImgSelect"
                            :isMultiple="false"
                          />
                        </b-col>

                        <b-col
                          cols="8"
                          v-if="page.translations.mr.mainSlider[index].image"
                        >
                          <b-img
                            v-bind="mainProps"
                            class="mb-1"
                            :src="page.translations.mr.mainSlider[index].image"
                          ></b-img>
                          <a
                            @click="
                              page.translations.mr.mainSlider[index].image = ''
                            "
                            class="text-danger d-block"
                            href="javascript:void(0);"
                            >Remove Thumbnail</a
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Title">
                      <b-form-input
                        v-model="page.translations.mr.mainSlider[index].title"
                        placeholder="Title"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="URL">
                      <b-form-input
                        v-model="page.translations.mr.mainSlider[index].url"
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Video URL">
                      <b-form-input
                        v-model="
                          page.translations.mr.mainSlider[index].videoUrl
                        "
                        placeholder="URL"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Description">
                      <ckeditor
                        v-model="
                          page.translations.mr.mainSlider[index].description
                        "
                        placeholder="Description"
                      ></ckeditor>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeSlider('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatSlider('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.mainSlider.length === 0
                      ? "Add Banner Slider"
                      : "Add New Slider"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>

        <b-card title="Experiences">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.translations.mr.experienceSection.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.mr.experienceSection.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="experience list"
              class="repeater-form"
              @submit.prevent="repeatExperience('mr')"
            >
              <b-row
                v-for="(slider, index) in page.translations.mr.experienceSection
                  .experiences"
                :key="index"
                ref="sliderRow"
              >
                <b-card :title="'Experience ' + (index + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.translations.mr.experienceSection
                                  .experiences[index].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group label="Locatiion">
                            <b-form-input
                              v-model="
                                page.translations.mr.experienceSection
                                  .experiences[index].location
                              "
                              placeholder="Locatiion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="URL">
                            <b-form-input
                              v-model="
                                page.translations.mr.experienceSection
                                  .experiences[index].url
                              "
                              placeholder="URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <b-form-checkbox
                              checked="true"
                              v-model="
                                page.translations.mr.experienceSection
                                  .experiences[index].comingSoon
                              "
                              name="check-button"
                              class="mb-1 mt-1"
                              switch
                              inline
                            >
                              Coming Soon
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index, lang: 'mr' }"
                                buttonText="Select Icon"
                                @mediaSelected="handleExperieeImgSelect"
                                :isMultiple="false"
                              />
                            </b-col>

                            <b-col
                              cols="8"
                              v-if="
                                page.translations.mr.experienceSection
                                  .experiences[index].image
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.translations.mr.experienceSection
                                    .experiences[index].image
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.translations.mr.experienceSection.experiences[
                                    index
                                  ].image = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeExperience('mr', index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatExperience('mr')"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.translations.mr.experienceSection.experiences
                        .length === 0
                        ? "Add Experience"
                        : "Add New Experience"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Stays">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.translations.mr.staySection.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.mr.staySection.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="experience list"
              class="repeater-form"
              @submit.prevent="repeatStays('mr')"
            >
              <b-row
                v-for="(slider, index) in page.translations.mr.staySection
                  .stays"
                :key="index"
                ref="sliderRow"
              >
                <b-card :title="'Stay ' + (index + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.translations.mr.staySection.stays[index]
                                  .title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group label="Locatiion">
                            <b-form-input
                              v-model="
                                page.translations.mr.staySection.stays[index]
                                  .location
                              "
                              placeholder="Locatiion"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="URL">
                            <b-form-input
                              v-model="
                                page.translations.mr.staySection.stays[index]
                                  .url
                              "
                              placeholder="URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <b-form-checkbox
                              checked="true"
                              v-model="
                                page.translations.mr.staySection.stays[index]
                                  .comingSoon
                              "
                              name="check-button"
                              class="mb-1 mt-1"
                              switch
                              inline
                            >
                              Coming Soon
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index, lang: 'mr' }"
                                buttonText="Select Icon"
                                @mediaSelected="handleExperieeImgSelect"
                                :isMultiple="false"
                              />
                            </b-col>

                            <b-col
                              cols="8"
                              v-if="
                                page.translations.mr.staySection.stays[index]
                                  .image
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.translations.mr.staySection.stays[index]
                                    .image
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.translations.mr.staySection.stays[
                                    index
                                  ].image = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeStay('mr', index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatStays('mr')"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.translations.mr.staySection.stays.length === 0
                        ? "Add Stays"
                        : "Add New Stay"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Majestic Maharashtra">
          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.mr.majesticMaharashtra.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Experience">
                  <v-select
                    multiple
                    label="title"
                    v-model="
                      page.translations.mr.majesticMaharashtra.experiences
                    "
                    :reduce="
                      (experience) => {
                        return { experience: experience._id };
                      }
                    "
                    placeholder="Select Experience Items"
                    :options="experiences"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="
                      page.translations.mr.majesticMaharashtra.description
                    "
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Upcoming Events">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.mr.eventSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.mr.eventSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="News">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.mr.newsSection.title"
                    placeholder="Page Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.mr.newsSection.description"
                    placeholder="Description"
                  ></ckeditor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>

        <b-card title="Social Media">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Section Title">
                  <b-form-input
                    v-model="page.translations.mr.socialMedia.title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <ckeditor
                    v-model="page.translations.mr.socialMedia.description"
                    placeholder="Description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              title="Media list"
              class="repeater-form"
              @submit.prevent="repeatSocialMedia('mr')"
            >
              <b-row
                v-for="(media, index1) in page.translations.mr.socialMedia
                  .socialMediaList"
                :key="index1"
                ref="sliderRow"
              >
                <b-card :title="'Social Media ' + (index1 + 1)">
                  <b-row>
                    <b-col cols="10">
                      <b-row>
                        <b-col cols="6">
                          <b-form-group label="Media Type">
                            <v-select
                              label="Media Type"
                              v-model="
                                page.translations.mr.socialMedia
                                  .socialMediaList[index1].mediaType
                              "
                              placeholder="Select Media Type"
                              :options="['Instagram', 'Twitter']"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group label="Redirect URL">
                            <b-form-input
                              v-model="
                                page.translations.mr.socialMedia
                                  .socialMediaList[index1].url
                              "
                              placeholder="Redirect URL"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group label="Description">
                            <ckeditor
                              v-model="
                                page.translations.mr.socialMedia
                                  .socialMediaList[index1].description
                              "
                              placeholder="Description"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols = "12">
                          <b-card title="News Date">
                            <b-row>
                             <b-col class="text-center">
                             <b-form-group>
                               <b-calendar v-model="page.translations.mr.socialMedia.socialMediaList[index1]
                                  .creationDate" block locale="en-US" />
                            </b-form-group>
                             </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col cols="6">
                          <b-row>
                            <b-col cols="4">
                              <ComponentMediaSelector
                                :params="{ index1, lang: 'mr' }"
                                buttonText="Select Image"
                                @mediaSelected="handleMediaSelect"
                                :isMultiple="false"
                              />
                            </b-col>
                            <b-col
                              cols="8"
                              v-if="
                                page.translations.mr.socialMedia
                                  .socialMediaList[index1].imageUrl
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                class="mb-1"
                                :src="
                                  page.translations.mr.socialMedia
                                    .socialMediaList[index1].imageUrl
                                "
                              ></b-img>
                              <a
                                @click="
                                  page.translations.mr.socialMedia.socialMediaList[
                                    index1
                                  ].imageUrl = ''
                                "
                                class="text-danger d-block"
                                href="javascript:void(0);"
                                >Remove Thumbnail</a
                              >
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="2" class="align-self-center">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        @click="removeSocialMedia('mr', index1)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1"
                  ><b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeatSocialMedia('mr')"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{
                      page.translations.mr.socialMedia.socialMediaList
                        .length === 0
                        ? "Add Social Media"
                        : "Add New Social media"
                    }}</span>
                  </b-button></b-col
                >
              </b-row>
            </b-form>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
  BCalendar,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BFormCheckbox,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
    BCalendar,
  },
  data() {
    return {
      isDraftAvailable: false,
      mainProps: {
        width: 75,
        height: 75,
      },
      experienceItems: [],
      experiences: [],
      page: {
        mainSlider: [],
        newsMarquee: [],
        experienceSection: {
          title: "",
          description: "",
          experiences: [],
        },
        staySection: {
          title: "",
          description: "",
          stays: [],
        },
        majesticMaharashtra: {
          title: "",
          description: "",
          experiences: [],
        },
        eventSection: {
          title: "",
          description: "",
        },
        newsSection: {
          title: "",
          description: "",
        },
        tourPackages: {
          title: "",
          description: "",
          tourPackageList: [],
        },
        socialMedia: {
          title: "",
          description: "",
          socialMediaList: [],
        },
        translations: {
          hi: {
            mainSlider: [],
            experienceSection: {
              title: "",
              description: "",
              experiences: [],
            },
            staySection: {
              title: "",
              description: "",
              stays: [],
            },
            majesticMaharashtra: {
              title: "",
              description: "",
              experiences: [],
            },
            eventSection: {
              title: "",
              description: "",
            },
            newsSection: {
              title: "",
              description: "",
            },
            tourPackages: {
              title: "",
              description: "",
              tourPackageList: [],
            },
            socialMedia: {
              title: "",
              description: "",
              socialMediaList: [],
            },
          },
          mr: {
            mainSlider: [],
            newsMarquee: [],
            experienceSection: {
              title: "",
              description: "",
              experiences: [],
            },
            staySection: {
              title: "",
              description: "",
              stays: [],
            },
            majesticMaharashtra: {
              title: "",
              description: "",
              experiences: [],
            },
            eventSection: {
              title: "",
              description: "",
            },
            newsSection: {
              title: "",
              description: "",
            },
            tourPackages: {
              title: "",
              description: "",
              tourPackageList: [],
            },
            socialMedia: {
              title: "",
              description: "",
              socialMediaList: [],
            },
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getExperienceItems() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/experienceItem/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log("experience item res", response.data);
          this.experienceItems = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Tender Data");
        });
    },
    removeExperience(lang = undeifned, index) {
      if (lang) {
        this.page.translations[lang].experienceSection.experiences.splice(
          index,
          1
        );
      } else {
        this.page.experienceSection.experiences.splice(index, 1);
      }
    },
    removeStay(lang = undeifned, index) {
      if (lang) {
        this.page.translations[lang].staySection.stays.splice(index, 1);
      } else {
        this.page.staySection.stays.splice(index, 1);
      }
    },
    handlebannerImgSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.page.translations[mediaObject.params.lang].mainSlider[
            mediaObject.params.index
          ].image = mediaObject.media.src;
        } else {
          this.page.mainSlider[mediaObject.params.index].image =
            mediaObject.media.src;
        }
      }
    },
    repeatSlider(lang = undefined) {
      if (lang) {
        this.page.translations[lang].mainSlider.push({
          title: "",
          image: "",
          description: "",
          url: "",
          videoUrl: "",
        });
      } else {
        this.page.mainSlider.push({
          title: "",
          image: "",
          description: "",
          url: "",
          videoUrl: "",
        });
      }
    },
    repeatNewsMarquee(lang = undefined) {
      if (lang) {
        this.page.translations[lang].newsMarquee.push({
          text: "",
          url: "",
        });
      } else {
        this.page.newsMarquee.push({
          text: "",
          url: "",
        });
      }
    },
    getExperiences() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/hotel/experiences/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log("experience res", response.data);
          this.experiences = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Tender Data");
        });
    },
    repeatExperience(lang = undefined) {
      if (lang) {
        this.page.translations[lang].experienceSection.experiences.push({
          title: "",
          image: "",
          url: "",
          location: "",
          comingSoon: false,
        });
      } else {
        this.page.experienceSection.experiences.push({
          title: "",
          image: "",
          url: "",
          location: "",
          comingSoon: false,
        });
      }
    },
    repeatStays(lang = undefined) {
      if (lang) {
        this.page.translations[lang].staySection.stays.push({
          title: "",
          image: "",
          url: "",
          location: "",
          comingSoon: false,
        });
      } else {
        this.page.staySection.stays.push({
          title: "",
          image: "",
          url: "",
          location: "",
          comingSoon: false,
        });
      }
    },
    repeatSocialMedia(lang = undefined) {
      if (lang) {
        this.page.translations[lang].socialMedia.socialMediaList.push({
          mediaType: "",
          imageUrl: "",
          description: "",
          url: "",
          creationDate: "",
        });
      } else {
        this.page.socialMedia.socialMediaList.push({
          mediaType: "",
          imageUrl: "",
          description: "",
          url: "",
          creationDate: "",
        });
      }
    },
    removeSocialMedia(lang = undefined, index) {
      console.log(index);
      if (lang) {
        this.page.translations[lang].socialMedia.socialMediaList.splice(
          index,
          1
        );
      } else {
        this.page.socialMedia.socialMediaList.splice(index, 1);
      }
    },
    removeSlider(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].mainSlider.splice(index, 1);
      } else {
        this.page.mainSlider.splice(index, 1);
      }
    },
    removeNewsMarquee(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].newsMarquee.splice(index, 1);
      } else {
        this.page.newsMarquee.splice(index, 1);
      }
    },
    saveDraft() {
      localStorage.setItem("homepage-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("homepage-data");
      this.page = JSON.parse(page);
      console.log(this.page);
    },
    removeDraft() {
      localStorage.removeItem("homepage-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/homepage/get-all`)
        .then((response) => {
          console.log("Response", response.data);
          if (response.data.HomePageData) {
            const {
              _id,
              mainSlider,
              experienceSection,
              majesticMaharashtra,
              eventSection,
              newsSection,
              tourPackages,
              socialMedia,
              translations,
              staySection,
              newsMarquee,
            } = response.data.HomePageData;
            const page = {
              _id,
              mainSlider,
              newsMarquee: newsMarquee ? newsMarquee : [],
              experienceSection: {
                title: experienceSection
                  ? experienceSection.title
                    ? experienceSection.title
                    : ""
                  : "",
                description: experienceSection
                  ? experienceSection.description
                    ? experienceSection.description
                    : ""
                  : "",
                experiences: experienceSection
                  ? experienceSection.experiences
                    ? experienceSection.experiences
                    : []
                  : [],
              },
              staySection: {
                title: staySection
                  ? staySection.title
                    ? staySection.title
                    : ""
                  : "",
                description: staySection
                  ? staySection.description
                    ? staySection.description
                    : ""
                  : "",
                stays: staySection
                  ? staySection.stays
                    ? staySection.stays
                    : []
                  : [],
              },
              majesticMaharashtra: {
                title: majesticMaharashtra
                  ? majesticMaharashtra.title
                    ? majesticMaharashtra.title
                    : ""
                  : "",
                description: majesticMaharashtra
                  ? majesticMaharashtra.description
                    ? majesticMaharashtra.description
                    : ""
                  : "",
                experiences: majesticMaharashtra
                  ? majesticMaharashtra.experiences
                    ? majesticMaharashtra.experiences
                    : []
                  : [],
              },
              eventSection: {
                title: eventSection
                  ? eventSection.title
                    ? eventSection.title
                    : ""
                  : "",
                description: eventSection
                  ? eventSection.description
                    ? eventSection.description
                    : ""
                  : "",
              },
              newsSection: {
                title: newsSection
                  ? newsSection.title
                    ? newsSection.title
                    : ""
                  : "",
                description: newsSection
                  ? newsSection.description
                    ? newsSection.description
                    : ""
                  : "",
              },
              tourPackages: {
                title: tourPackages
                  ? tourPackages.title
                    ? tourPackages.title
                    : ""
                  : "",
                description: tourPackages
                  ? tourPackages.description
                    ? tourPackages.description
                    : ""
                  : "",
                tourPackageList: tourPackages
                  ? tourPackages.tourPackageList
                    ? tourPackages.tourPackageList
                    : []
                  : [],
              },
              socialMedia: {
                title: socialMedia
                  ? socialMedia.title
                    ? socialMedia.title
                    : ""
                  : "",
                description: socialMedia
                  ? socialMedia.description
                    ? socialMedia.description
                    : ""
                  : "",
                socialMediaList: socialMedia
                  ? socialMedia.socialMediaList
                    ? socialMedia.socialMediaList
                    : []
                  : [],
              },
              translations: {
                hi: {
                  mainSlider: translations
                    ? translations.hi.mainSlider
                      ? translations.hi.mainSlider
                      : []
                    : [],
                  experienceSection: {
                    title: translations
                      ? translations.hi.experienceSection
                        ? translations.hi.experienceSection.title
                          ? translations.hi.experienceSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.experienceSection
                        ? translations.hi.experienceSection.description
                          ? translations.hi.experienceSection.description
                          : ""
                        : ""
                      : "",
                    experiences: translations
                      ? translations.hi.experienceSection
                        ? translations.hi.experienceSection.experiences
                          ? translations.hi.experienceSection.experiences
                          : []
                        : []
                      : [],
                  },
                  majesticMaharashtra: {
                    title: translations
                      ? translations.hi.majesticMaharashtra
                        ? translations.hi.majesticMaharashtra.title
                          ? translations.hi.majesticMaharashtra.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.majesticMaharashtra
                        ? translations.hi.majesticMaharashtra.description
                          ? translations.hi.majesticMaharashtra.description
                          : ""
                        : ""
                      : "",
                    experiences: translations
                      ? translations.hi.majesticMaharashtra
                        ? translations.hi.majesticMaharashtra.experiences
                          ? translations.hi.majesticMaharashtra.experiences
                          : []
                        : []
                      : [],
                  },
                  eventSection: {
                    title: translations
                      ? translations.hi.eventSection
                        ? translations.hi.eventSection.title
                          ? translations.hi.eventSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.eventSection
                        ? translations.hi.eventSection.description
                          ? translations.hi.eventSection.description
                          : ""
                        : ""
                      : "",
                  },
                  newsSection: {
                    title: translations
                      ? translations.hi.newsSection
                        ? translations.hi.newsSection.title
                          ? translations.hi.newsSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.newsSection
                        ? translations.hi.newsSection.description
                          ? translations.hi.newsSection.description
                          : ""
                        : ""
                      : "",
                  },
                  tourPackages: {
                    title: translations
                      ? translations.hi.tourPackages
                        ? translations.hi.tourPackages.title
                          ? translations.hi.tourPackages.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.tourPackages
                        ? translations.hi.tourPackages.description
                          ? translations.hi.tourPackages.description
                          : ""
                        : ""
                      : "",
                    tourPackageList: translations
                      ? translations.hi.tourPackages
                        ? translations.hi.tourPackages.tourPackageList
                          ? translations.hi.tourPackages.tourPackageList
                          : []
                        : []
                      : [],
                  },
                  socialMedia: {
                    title: translations
                      ? translations.hi.socialMedia
                        ? translations.hi.socialMedia.title
                          ? translations.hi.socialMedia.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.hi.socialMedia
                        ? translations.hi.socialMedia.description
                          ? translations.hi.socialMedia.description
                          : ""
                        : ""
                      : "",
                    socialMediaList: translations
                      ? translations.hi.socialMedia
                        ? translations.hi.socialMedia.socialMediaList
                          ? translations.hi.socialMedia.socialMediaList
                          : []
                        : []
                      : [],
                  },
                },
                mr: {
                  mainSlider: translations
                    ? translations.mr.mainSlider
                      ? translations.mr.mainSlider
                      : []
                    : [],
                  newsMarquee: translations
                    ? translations.mr.newsMarquee
                      ? translations.mr.newsMarquee
                      : []
                    : [],
                  experienceSection: {
                    title: translations
                      ? translations.mr.experienceSection
                        ? translations.mr.experienceSection.title
                          ? translations.mr.experienceSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.experienceSection
                        ? translations.mr.experienceSection.description
                          ? translations.mr.experienceSection.description
                          : ""
                        : ""
                      : "",
                    experiences: translations
                      ? translations.mr.experienceSection
                        ? translations.mr.experienceSection.experiences
                          ? translations.mr.experienceSection.experiences
                          : []
                        : []
                      : [],
                  },
                  staySection: {
                    title: translations
                      ? translations.mr.staySection
                        ? translations.mr.staySection.title
                          ? translations.mr.staySection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.staySection
                        ? translations.mr.staySection.description
                          ? translations.mr.staySection.description
                          : ""
                        : ""
                      : "",
                    stays: translations
                      ? translations.mr.staySection
                        ? translations.mr.staySection.stays
                          ? translations.mr.staySection.stays
                          : []
                        : []
                      : [],
                  },
                  majesticMaharashtra: {
                    title: translations
                      ? translations.mr.majesticMaharashtra
                        ? translations.mr.majesticMaharashtra.title
                          ? translations.mr.majesticMaharashtra.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.majesticMaharashtra
                        ? translations.mr.majesticMaharashtra.description
                          ? translations.mr.majesticMaharashtra.description
                          : ""
                        : ""
                      : "",
                    experiences: translations
                      ? translations.mr.majesticMaharashtra
                        ? translations.mr.majesticMaharashtra.experiences
                          ? translations.mr.majesticMaharashtra.experiences
                          : []
                        : []
                      : [],
                  },
                  eventSection: {
                    title: translations
                      ? translations.mr.eventSection
                        ? translations.mr.eventSection.title
                          ? translations.mr.eventSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.eventSection
                        ? translations.mr.eventSection.description
                          ? translations.mr.eventSection.description
                          : ""
                        : ""
                      : "",
                  },
                  newsSection: {
                    title: translations
                      ? translations.mr.newsSection
                        ? translations.mr.newsSection.title
                          ? translations.mr.newsSection.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.newsSection
                        ? translations.mr.newsSection.description
                          ? translations.mr.newsSection.description
                          : ""
                        : ""
                      : "",
                  },
                  tourPackages: {
                    title: translations
                      ? translations.mr.tourPackages
                        ? translations.mr.tourPackages.title
                          ? translations.mr.tourPackages.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.tourPackages
                        ? translations.mr.tourPackages.description
                          ? translations.mr.tourPackages.description
                          : ""
                        : ""
                      : "",
                    tourPackageList: translations
                      ? translations.mr.tourPackages
                        ? translations.mr.tourPackages.tourPackageList
                          ? translations.mr.tourPackages.tourPackageList
                          : []
                        : []
                      : [],
                  },
                  socialMedia: {
                    title: translations
                      ? translations.mr.socialMedia
                        ? translations.mr.socialMedia.title
                          ? translations.mr.socialMedia.title
                          : ""
                        : ""
                      : "",
                    description: translations
                      ? translations.mr.socialMedia
                        ? translations.mr.socialMedia.description
                          ? translations.mr.socialMedia.description
                          : ""
                        : ""
                      : "",
                    socialMediaList: translations
                      ? translations.mr.socialMedia
                        ? translations.mr.socialMedia.socialMediaList
                          ? translations.mr.socialMedia.socialMediaList
                          : []
                        : []
                      : [],
                  },
                },
              },
            };
            console.log("Page", page);
            this.page = page;
            const experienceList = response.data.HomePageData
              .majesticMaharashtra.experiences
              ? response.data.HomePageData.majesticMaharashtra.experiences.map(
                  (experienceItem) => {
                    return {
                      experience: experienceItem.experience._id,
                    };
                  }
                )
              : [];
            this.page.majesticMaharashtra.experiences = experienceList;

            const experienceListHi = response.data.HomePageData.translations
              ? response.data.HomePageData.translations.hi.majesticMaharashtra
                ? response.data.HomePageData.translations.hi.majesticMaharashtra
                    .experiences
                  ? response.data.HomePageData.translations.hi.majesticMaharashtra.experiences.map(
                      (experienceItem) => {
                        return {
                          experience: experienceItem.experience._id,
                        };
                      }
                    )
                  : []
                : []
              : [];
            this.page.translations.hi.majesticMaharashtra.experiences =
              experienceListHi;

            const experienceListMr = response.data.HomePageData.translations
              ? response.data.HomePageData.translations.mr.majesticMaharashtra
                ? response.data.HomePageData.translations.mr.majesticMaharashtra
                    .experiences
                  ? response.data.HomePageData.translations.mr.majesticMaharashtra.experiences.map(
                      (experienceItem) => {
                        return {
                          experience: experienceItem.experience._id,
                        };
                      }
                    )
                  : []
                : []
              : [];
            this.page.translations.mr.majesticMaharashtra.experiences =
              experienceListMr;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleMediaSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.page.translations[
            mediaObject.params.lang
          ].socialMedia.socialMediaList[mediaObject.params.index1].imageUrl =
            mediaObject.media.src;
        } else {
          this.page.socialMedia.socialMediaList[
            mediaObject.params.index1
          ].imageUrl = mediaObject.media.src;
        }
      }
    },
    handleExperieeImgSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.page.translations[
            mediaObject.params.lang
          ].experienceSection.experiences[mediaObject.params.index].image =
            mediaObject.media.src;
        } else {
          this.page.experienceSection.experiences[
            mediaObject.params.index
          ].image = mediaObject.media.src;
        }
      }
    },
    handleStayImgSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.page.translations[mediaObject.params.lang].staySection.stays[
            mediaObject.params.index
          ].image = mediaObject.media.src;
        } else {
          this.page.staySection.stays[mediaObject.params.index].image =
            mediaObject.media.src;
        }
      }
    },
    savePage() {
      console.log(this.page);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/pages/homepage/save`,
          this.page,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log("saved");
          this.makeToast(
            "success",
            "Success",
            `HomePage has been added successfully!`
          );
        })
        .catch((error) => {
          this.makeToast("danger", "Error", "Error while adding the tender!");
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getExperienceItems();
    this.getExperiences();
    if (localStorage.getItem("homepage-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>